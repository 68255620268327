import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { useScrollToTop } from './utils/tools';
import classes from './About.module.css';

const About = () => {
  useScrollToTop();
  return (
    <Container fluid className={classes.AboutContainer}>
      <Col className={classes.About}>
        <img
          src="https://storage.googleapis.com/by-data/ohad/about_image.jpg"
          alt="About page"
          className={classes.AboutImg}
        />
        <p>
          Welcome to my website! I'm Alon, a big fan of sea kayaking and
          exploring wild places. I've been on some amazing adventures around the
          world, from the icy Arctic to sunny Australia and New Zealand. I've
          paddled in places like the Faroe Islands and Ireland, and I've even
          tackled exciting white-water kayaking on the White Nile.
        </p>
        <p>
          On this site, you'll find stories and pictures from my expeditions.
          Unfortunately, my plans to visit British Columbia got delayed due to
          Covid-19, but I'm excited to finally make that trip next summer.
        </p>
        <p>
          A huge thanks to Amber Champion from{' '}
          <a
            href="https://bluedogkayaking.com/"
            target="_blank"
            rel="noreferrer"
          >
            Blue Dog Kayaking
          </a>{' '}
          for all their help in planning my upcoming journey.
        </p>
        <p>
          Got any questions? Feel free to email me at{' '}
          <a href="mailto:alon+kayak@ohad.info">alon@ohad.info</a>
        </p>
        <p>See you on the water, Alon</p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          <iframe
            className="rumble"
            width="640"
            height="360"
            title="9Lives - Svalbard Expedition"
            src="https://rumble.com/embed/v48ajec/?pub=f7o29"
            allowFullScreen
          ></iframe>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '2rem',
          }}
        >
          <iframe
            className="rumble"
            width="640"
            height="360"
            src="https://rumble.com/embed/v489pyr/?pub=f7o29"
            allowFullScreen
            title="Silverback Uganda"
          ></iframe>
        </div>
      </Col>
    </Container>
  );
};

export default About;
